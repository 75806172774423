/**
 * application api endpoint will configure here
 */
import { Injectable } from '@angular/core';

import { environment } from '~environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  static get localhost(): string {
    return window.location.origin + '/assets/api/';
  }

  static get host(): string {
    return environment.host;
  }

  protected localPingURL(): string {
    return UrlService.localhost + 'ping.json';
  }

  /**
   * ****************** auth ******************
   */

  protected get organizationByEmailURL(): string {
    return UrlService.host + 'auth/user-by-email';
  }

  protected get loginURL(): string {
    return UrlService.host + 'auth/login';
  }

  protected get forgotPasswordURL(): string {
    return UrlService.host + 'auth/forgot-password';
  }

  protected get verifySessionTokenURL(): string {
    return UrlService.host + 'auth/verify-session-token';
  }

  protected get resetPasswordURL(): string {
    return UrlService.host + 'auth/reset-password';
  }

  protected get changePasswordURL(): string {
    return UrlService.host + 'auth/change-password';
  }

  protected get setPasswordURL(): string {
    return UrlService.host + 'auth/set-password';
  }

  protected get aclURL(): string {
    return UrlService.host + 'auth/access-control-list';
  }

  protected get whoiamURL(): string {
    return UrlService.host + 'auth/whoami';
  }

  /**
   * ****************** users ******************
   */

  protected get usersURL(): string {
    return UrlService.host + 'users';
  }

  protected get revokeAccessURL(): string {
    return UrlService.host + 'users/provide-access';
  }

  protected get removeAccessURL(): string {
    return UrlService.host + 'users/remove-access';
  }

  protected get resendAuthLinkURL(): string {
    return UrlService.host + 'users/resend-auth-link';
  }

  /**
   * ****************** cherwell ******************
   */
  protected get cherwellURL(): string {
    return UrlService.host + 'cherwell';
  }

  protected get servicesURL(): string {
    return UrlService.host + 'cherwell/services';
  }

  protected get ticketsURL(): string {
    return UrlService.host + 'cherwell/tickets';
  }

  /**
   * ****************** salesforce ******************
   */
  protected get accountURL(): string {
    return UrlService.host + 'salesforce/account';
  }

  protected get myCompanyURL(): string {
    return UrlService.host + 'salesforce/my-company';
  }

  /**
   * ****************** services ******************
   */
  protected get servicesAllURL(): string {
    return UrlService.host + 'service-line';
  }

  protected get ticketData() {
    return UrlService.host + 'auth/tickets';
  }

  protected get serviceData() {
    return UrlService.host + 'auth/services';
  }

  protected get uploadData() {
    return UrlService.host + 'upload-attachment';
  }

  protected get downloadFile() {
    return UrlService.host + 'download-file';
  }
  protected get servicesTagURL(): string {
    return UrlService.host + 'auth/services-tags';
  }
  protected get getservicesTagURL(): string {
    return UrlService.host + 'auth/services-tags';
  }
  protected get updateservicesTagURL(): string {
    return UrlService.host + 'auth/services-tags/';
  }
  protected get ticketAllURL(): string {
    return UrlService.host + 'auth/ticket';
  }

  protected get searchAllURL(): string {
    return UrlService.host + `auth/services-search`;
  }

  protected get userAdmin(): string {
    return UrlService.host + 'users-admin';
  }

  protected get getOrganization(): string {
    return UrlService.host + 'organizations';
  }

  protected get userStatus(): string {
    return UrlService.host + 'auth-users';
  }

  protected get serviceDevice(): string {
    return UrlService.host + 'service-device';
  }

  protected get serviceItem(): string {
    return UrlService.host + 'service-items';
  }

  protected get serviceHistory(): string {
    return UrlService.host + 'service-history';
  }



  /**
   * ****************** tickets ******************
   */
  protected get ticketsAllURL(): string {
    return UrlService.host + 'auth/tickets';
  }

  protected get ticketsByIdURL(): string {
    return UrlService.host + 'auth/ticket';
  }

  /** dashboard cards */

  protected get dashboardCardsURL(): string {
    return UrlService.host + 'auth/services-dashboard';
  }

  protected get servicesFilterURL(): string {
    return UrlService.host + 'auth/services';
  }

  protected get statusFilterURL(): string {
    return UrlService.host + 'auth/status';
  }

  protected get incidentURL(): string {
    return UrlService.host + 'auth/incident';
  }

  /** diagnostics */

  protected get diagnosticsAlertURL(): string {
    return UrlService.host + 'services/alert';
  }

  protected get diagnosticsActiveAlertURL(): string {
    return UrlService.host + 'services/active-alarm';
  }

  protected get diagnosticsAlerByHosttURL(): string {
    return UrlService.host + 'device-events';
  }

  protected get hostService(): string {
    return UrlService.host + 'services-host-dashboard';
  }

  protected get servicePorts(): string {
    return UrlService.host + 'service-device-ports';
  }

  protected get icmpPorts(): string {
    return UrlService.host + 'icmp-device-port';
  }

  protected get deviceGraphsPortsURL(): string {
    return UrlService.host + 'device-port';
  }
}
